.firma{
    height: 100px;
    grid-column: 1 / 4;
    grid-row: 4 / 5;
    position: relative;
    margin-top: 20px;
    width: 100%;
    .textarea{
      height: 94px;
      resize: none;
      position: relative;
      color: #000;
      background-color: rgba(0, 5, 64, 0.08);
      border: none;
      border-radius: 1.5rem 1.5rem 0 0;
      width: 388px;
      @include mobile{
          width: 330px;
      }
      border-bottom: 1px solid #000540;
        #fileinput{
            display: none;
            width: 0;
            height: 0;
        }
        .opt-1{
            display: none;
            width: 100%;
        }
        .opt-2{
            display: none;
            span{
              left: 0;
              position: absolute;
              bottom: -18px;
            }
        }
        .opt-3{
            display: none;
        }
        .signatur{
            cursor:sw-resize;
            .sig-buton{
                position: absolute;
                bottom: -25px;
                right: 0;
                button{
                    width: 60px;
                    background:#000;
                    color: #fff;
                    border: 0;
                    padding: 3px;
                    border-radius: 5px;
                    font-size: 13px;
                    margin-left: 3px;
                    &:focus{
                        background: #ccc
                    }
                }
            }
        }
        .img_signa{
            height: 68px;
        }
        textarea{
            width: 100%;
            font-size: 2.5rem;
            padding: 1rem 10px 0 10px;
            height: 100%;
            position: relative;
            background: transparent;
            border: 0;
            resize: none;
            outline: transparent !important;
            &::placeholder{
                color: #BABABA;
            }
        }
    }
    .brns{
        display: flex;
        position: absolute;
        bottom: 4px;
        right: 5px;
        .brn{
            height: 16px;
            width: 20px;
            padding: 0;
            border: 0;
            background: transparent;
            outline: transparent !important;
            &.active{
                .icono{
                    background-color: #FB6300;
                }
            }
            &:nth-child(1){
                margin-top: 0px;
                height: 16px;
                width: 16px;
            }
            &:nth-child(2){
                margin-top: 0px;
            }
            &:nth-child(3){
                margin-top: 0px;
                height: 15px;
            }
            & > img{
                max-height: 100%;
                max-width: 100%;
            }
        }
    }
    @include tablet{
        // grid-column: 1 / 5;
        // grid-row: 4 ;
    }
    @include mobile{
        // grid-column: 1 / 5;
        // grid-row: 4 ;
    }
}


.icon-text{
  height:16px;
  width:16px;
  max-width: 100%;
  max-height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #000540;
  -webkit-mask: no-repeat center;
  mask: no-repeat center;
  mask-image: url('../../images/text.svg');
  -webkit-mask-image: url('../../images/text.svg');
  display: flex;
}
.icon-pen{
  height:16px;
  width:16px;
  max-width: 100%;
  max-height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #000540;
  -webkit-mask: no-repeat center;
  mask: no-repeat center;
  mask-image: url('../../images/pen.svg');
  -webkit-mask-image: url('../../images/pen.svg');
  display: flex;
}
.icon-camera{
  height:16px;
  width:16px;
  max-width: 100%;
  max-height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #000540;
  -webkit-mask: no-repeat center;
  mask: no-repeat center;
  mask-image: url('../../images/camera.svg');
  -webkit-mask-image: url('../../images/camera.svg');
  display: flex;
}