// @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

@font-face {
	font-family: 'Candara';
	src: url('../../fonts/CANDARA.ttf');
}

@font-face {
	font-family: 'quicksand-regular';
	src: url('../../fonts/Quicksand-Regular_1.woff');
}

@font-face {
	font-family: 'quicksand-semibold';
	src: url('../../fonts/Quicksand-SemiBold_1.woff');
}

@font-face {
	font-family: 'quicksand-medium';
	src: url('../../fonts/Quicksand-Medium.woff');
}

@font-face {
	font-family: 'quicksand-light';
	src: url('../../fonts/Quicksand-Light.woff');
}

@font-face {
	font-family: 'quicksand-bold';
	src: url('../../fonts/Quicksand-Bold.woff');
}

@font-face {
	font-family: 'product-sans';
	src: url('../../fonts/ProductSans.ttf');
}

@font-face {
	font-family: 'product-sans-bold';
	src: url('../../fonts/ProductSansBold.ttf');
}

@font-face {
	font-family: 'product-sans-bold-italic';
	src: url('../../fonts/ProductSansBoldItalic.ttf');
}

@font-face {
	font-family: 'product-sans-italic';
	src: url('../../fonts/ProductSansItalic.ttf');
}

html {
	font-family: 'product-sans';
	box-sizing: border-box;
	font-size: 62.5%; /* 62.5% = 10px */
}
*,
*:before,
*:after {
	box-sizing: inherit;
}
h1 {
	font-size: 5rem;
}
h2 {
	font-size: 4.5rem;
}
h3 {
	font-size: 4rem;
}
small,
.small {
	font-size: 2rem;
}
img {
	width: 100%;
	height: auto;
}
select {
	appearance: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}
