label {
  display: block;
  text-align: left;
  color: $gray2;
  font-family: 'product-sans';
  font-size: 1.5rem;
  // z-index: 2;
}

.input {
  display: block;
  text-align: left;
  color: $blue-dark;
  font-family: 'quicksand-regular';
  font-size: 1.7rem;
  z-index: 0;
}

.input-field {
  position: relative;
  margin-top: 0;
  transition: all 0.2s;
  z-index: 1;

  &.group-fill {
    margin-top: 30px !important;
    border-bottom: 1px solid $blue-dark;
    -webkit-box-shadow: 0 0 0 0 $blue-dark;
    box-shadow: 0 0 0 0 $blue-dark;
    background-color: rgba($color: $blue-dark, $alpha: 0.08);
    border-radius: 1.5rem 1.5rem 0 0;

    label {
      font-size: 1.8rem;
    }
  }

  &.camp4,
  &.camp5,
  &.camp14 {
    z-index: 3;
  }

  &.camp14 {
    z-index: 2;

    &.es {
      label {
        top: -21px;
        width: 90%;
      }
    }
  }

  &.camp2 {
    z-index: 7;
  }

  &.camp4 {
    z-index: 6;
  }

  &.camp5 {
    z-index: 5;
  }

  &.camp13 {
    z-index: 4;
  }
}


@media only screen and (min-width: $tablet) {
  .input-field {
    position: relative;
    margin-top: 0;
    margin-bottom: 0rem;
    transition: all 0.2s;

    &.camp4,
    &.camp5,
    &.camp14 {
      flex: 1;
      z-index: 3;
    }

    &.camp14 {
      flex: 1;
      z-index: 2;
    }

    &.group-fill {
      margin-top: 20px !important;
    }

    &.camp2 {
      z-index: 7;
    }

    &.camp4 {
      z-index: 6;
    }

    &.camp5 {
      z-index: 5;
    }

    &.camp13 {
      z-index: 4;
    }

  }
}

.input-field.col label {
  left: 0;
}

.input-field>label,
.input-field>label.lb-large {
  color: $gray2;
  position: absolute;
  top: -3px;
  left: 0;
  font-size: 1.5rem;
  cursor: text;
  -webkit-transition: color 0.2s ease-out, -webkit-transform 0.2s ease-out;
  transition: color 0.2s ease-out, -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out, color 0.2s ease-out;
  transition: transform 0.2s ease-out, color 0.2s ease-out,
    -webkit-transform 0.2s ease-out;
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  text-align: initial;
  -webkit-transform: translateY(20px);
  transform: translateY(18px);
}

@media only screen and (max-width: $desktop) {
  .input-field>label.lb-large {
    position: absolute;
    top: -31px;
  }
}

.input-field>label:not(.label-icon).active {
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
  -webkit-transform: translateY(-15px) scale(0.8);
  transform: translateY(-15px) scale(0.8);
}

input:not([type]),
input[type='text']:not(.browser-default),
input[type='text']:not(.states_licensed.input),
input[type='password']:not(.browser-default),
input[type='email']:not(.browser-default),
input[type='url']:not(.browser-default),
input[type='time']:not(.browser-default),
input[type='date']:not(.browser-default),
input[type='datetime']:not(.browser-default),
input[type='datetime-local']:not(.browser-default),
input[type='tel']:not(.browser-default),
input[type='number']:not(.browser-default),
input[type='search']:not(.browser-default),
textarea.materialize-textarea {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid $gray;
  border-radius: 1.5rem 1.5rem 0 0;
  outline: none;
  height: 3.4rem;
  width: 100%;
  font-size: 1.6rem;
  margin: 0;
  padding: 0 1rem;

  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: content-box;
  box-sizing: border-box;
  -webkit-transition: border 0.3s, -webkit-box-shadow 0.3s;
  transition: border 0.3s, -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s, border 0.3s, top 2s ease-in-out;
  transition: box-shadow 0.3s, border 0.3s, top 0.5s ease-in-out,
    margin-bottom 0.5s ease-in-out, -webkit-box-shadow 0.3s;
}

input:not([type]):focus:not([readonly]),
input[type='text']:not(.states_licensed.input):focus:not(.states_licensed.input),
// input[type='text']:not(.browser-default):focus:not([readonly]),
input[type='password']:not(.browser-default):focus:not([readonly]),
input[type='email']:not(.browser-default):focus:not([readonly]),
input[type='url']:not(.browser-default):focus:not([readonly]),
input[type='time']:not(.browser-default):focus:not([readonly]),
input[type='date']:not(.browser-default):focus:not([readonly]),
input[type='datetime']:not(.browser-default):focus:not([readonly]),
input[type='datetime-local']:not(.browser-default):focus:not([readonly]),
input[type='tel']:not(.browser-default):focus:not([readonly]),
input[type='number']:not(.browser-default):focus:not([readonly]),
input[type='search']:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]),
.signature {
  border-bottom: 1px solid $blue-dark;
  -webkit-box-shadow: 0 0 0 0 $blue-dark;
  box-shadow: 0 0 0 0 $blue-dark;
  background-color: rgba($color: $blue-dark, $alpha: 0.08);
  border-radius: 1.5rem 1.5rem 0 0;
}

.chips {
  input:focus {
    background: transparent !important;
    border: 0 !important;
  }
}

input:not([type]):focus:not([readonly])+label,
input[type='text']:not(.browser-default):focus:not([readonly])+label,
input[type='password']:not(.browser-default):focus:not([readonly])+label,
input[type='email']:not(.browser-default):focus:not([readonly])+label,
input[type='url']:not(.browser-default):focus:not([readonly])+label,
input[type='time']:not(.browser-default):focus:not([readonly])+label,
input[type='date']:not(.browser-default):focus:not([readonly])+label,
input[type='datetime']:not(.browser-default):focus:not([readonly])+label,
input[type='datetime-local']:not(.browser-default):focus:not([readonly])+label,
input[type='tel']:not(.browser-default):focus:not([readonly])+label,
input[type='number']:not(.browser-default):focus:not([readonly])+label,
input[type='search']:not(.browser-default):focus:not([readonly])+label,
textarea.materialize-textarea:focus:not([readonly])+label,
.input:not(.browser-default):focus:not([readonly])+.label {
  color: $blue-dark  !important;
}

.input-file {
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  display: none;
}


input:not([type]):disabled,
input:not([type])[readonly="readonly"],
input[type=text]:not(.browser-default):disabled,
input[type=text]:not(.browser-default)[readonly="readonly"],
input[type=password]:not(.browser-default):disabled,
input[type=password]:not(.browser-default)[readonly="readonly"],
input[type=email]:not(.browser-default):disabled,
input[type=email]:not(.browser-default)[readonly="readonly"],
input[type=url]:not(.browser-default):disabled,
input[type=url]:not(.browser-default)[readonly="readonly"],
input[type=time]:not(.browser-default):disabled,
input[type=time]:not(.browser-default)[readonly="readonly"],
input[type=date]:not(.browser-default):disabled,
input[type=date]:not(.browser-default)[readonly="readonly"],
input[type=datetime]:not(.browser-default):disabled,
input[type=datetime]:not(.browser-default)[readonly="readonly"],
input[type=datetime-local]:not(.browser-default):disabled,
input[type=datetime-local]:not(.browser-default)[readonly="readonly"],
input[type=tel]:not(.browser-default):disabled,
input[type=tel]:not(.browser-default)[readonly="readonly"],
input[type=number]:not(.browser-default):disabled,
input[type=number]:not(.browser-default)[readonly="readonly"],
input[type=search]:not(.browser-default):disabled,
input[type=search]:not(.browser-default)[readonly="readonly"],
textarea.materialize-textarea:disabled,
textarea.materialize-textarea[readonly="readonly"] {
  color: $blue-dark;
}

input:not([type]):disabled+label,
input:not([type])[readonly="readonly"]+label,
input[type=text]:not(.browser-default):disabled+label,
input[type=text]:not(.browser-default)[readonly="readonly"]+label,
input[type=password]:not(.browser-default):disabled+label,
input[type=password]:not(.browser-default)[readonly="readonly"]+label,
input[type=email]:not(.browser-default):disabled+label,
input[type=email]:not(.browser-default)[readonly="readonly"]+label,
input[type=url]:not(.browser-default):disabled+label,
input[type=url]:not(.browser-default)[readonly="readonly"]+label,
input[type=time]:not(.browser-default):disabled+label,
input[type=time]:not(.browser-default)[readonly="readonly"]+label,
input[type=date]:not(.browser-default):disabled+label,
input[type=date]:not(.browser-default)[readonly="readonly"]+label,
input[type=datetime]:not(.browser-default):disabled+label,
input[type=datetime]:not(.browser-default)[readonly="readonly"]+label,
input[type=datetime-local]:not(.browser-default):disabled+label,
input[type=datetime-local]:not(.browser-default)[readonly="readonly"]+label,
input[type=tel]:not(.browser-default):disabled+label,
input[type=tel]:not(.browser-default)[readonly="readonly"]+label,
input[type=number]:not(.browser-default):disabled+label,
input[type=number]:not(.browser-default)[readonly="readonly"]+label,
input[type=search]:not(.browser-default):disabled+label,
input[type=search]:not(.browser-default)[readonly="readonly"]+label,
textarea.materialize-textarea:disabled+label,
textarea.materialize-textarea[readonly="readonly"]+label {
  color: $blue-dark;
}