button:focus {
  outline: none;
  background-color: $blue-dark;
}

.button {
  background-color: $blue-dark;
  color: $white;
  font-size: $button;
  width: 23rem;
  height: 6.8rem;
  border-radius: 3.5rem;
  margin: 6rem auto 4rem;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.button2 {
  background-color: $blue-dark;
  color: $white;
  font-size: 2rem;
  width: fit-content;
  padding: 0 15px;
  min-width: 10.8rem;
  height: 3.6rem;
  border: none;
  border-radius: 2rem;
  font-family: 'quicksand-regular';
  margin: 0 auto 2rem;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.button_u {
  display: inline-flex;
  align-items: center;
  padding: 0 8px;
  width: fit-content;
  max-width: 14.5rem;
  height: 3.4rem;
  margin: 0;
  font-size: 1.3rem;
  text-transform: uppercase;
  background-color: #FFFFFF;
  color: #000540;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #00E5BA;
  border-radius: 2rem;
  cursor: pointer;

  .icon {
    height: 22px;
    // margin-right: 8px;
  }

  span {
    font-family: 'quicksand-bold';
  }

  &:hover {
    background-color: #00E5BA;
  }
}


.button-file {
  background-color: $blue-dark;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: $white;
  width: 15rem;
  height: 3.4rem;
  border-radius: 30px 14px 14px 30px;
  font-size: 1.3rem;
  font-family: 'quicksand-bold';
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }

  .icon {
    background: #00E5BA;
    width: 12px;
    height: 12px;
  }
}

.flechas,
.firma {
  position: absolute;
  display: flex;
}

.flechas {
  width: 1rem;
  height: 3rem;
  flex-direction: column;
  justify-content: space-evenly;
  right: 1rem;
  top: 0.4rem;

  img {
    width: 1rem;
  }
}

.check-box {
  margin-top: 60px;
  padding: 0 6%;
}