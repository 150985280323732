.chip {
	position: relative;
	display: inline-block;
	height: 32px;
	font-size: 18px;
	font-family: 'quicksand-bold';
	color: $blue-dark;
	line-height: 32px;
	padding: 0 12px;
	border-radius: 16px;
	background-color: #fff;
	margin-top: 7px;
	margin-bottom: 9px;
	margin-right: 11px;
	box-shadow: 0px 3px 6px #00000029;
}

.fill,
.chips.focus {
	border-bottom: 1px solid $blue-dark !important;
	-webkit-box-shadow: 0 0 0 0 $blue-dark !important;
	box-shadow: 0 0 0 0 $blue-dark !important;
	background-color: rgba($color: $blue-dark, $alpha: 0.08) !important;
	border-radius: 1.5rem 1.5rem 0 0 !important;
	padding: .5rem 1rem 0;
	height: auto;
}

.chips .input:focus,
.chips .input,
.chips .input.states_licensed {
	background: none;
	border: none;
	color: $blue-dark;
	display: inline-block;
	font-size: 1.8rem;
	height: 2.7rem;
	line-height: 3rem;
	outline: 0;
	margin: 0;
	padding: 0 !important;
	width: 120px !important;
}

.chip .close {
	position: relative;
	margin-left: 0.5rem;
	display: inline;
	opacity: 1;
	cursor: pointer;
	float: right;
	font-size: 18px;
	line-height: 32px;
	padding-left: 8px;
	text-indent: -999999px;
}
.chip .close::after {
	content: 'x';
	width: 7px;
	height: 7px;
	opacity: 1;
	color: $light-blue;
	position: absolute;
	right: 0;
	text-indent: 0px;
	top: 0;
}

.chips {
	border: none;
	border-bottom: 1px solid $gray;
	-webkit-box-shadow: none;
	box-shadow: none;
	margin: 0;
	height: 4.4rem;
	outline: none;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	& > div {
		width: 100%;
    background: transparent !important;
		border: none !important;
	}
	input {
		margin: 0 !important;
		padding: 0 !important;
		margin-top: -5px !important;
	}
}

#react-autowhatever-1{
	width: 100% !important;
	ul{
		max-height: 400px !important;
    background: #000540 !important;
    border-radius: 0 0 20px 20px !important;
    top: 100% !important;
    padding-top: 0 !important;
    color: #fff !important;
		width: 100% !important;
		li{

			padding: 10px 10px !important;
    	line-height: 1 !important;
    	text-align: center !important;
			cursor: default !important;
			display: block !important;
			font-size: inherit !important;
			padding: 8px 12px !important;
			width: 100% !important;
			user-select: none !important;
			-webkit-tap-highlight-color: rgba(0,0,0,0) !important;
			box-sizing: border-box !important;
			font-family: "quicksand-regular" !important;
			font-size: 1.7rem !important;
			&[aria-selected="true"]{
				  color: #fff !important; 
				  color: #00E5BA !important;
    			background: rgba(255, 255, 255, 0.259) !important;
			}
			&:hover{
				  color: #fff !important; 
					color: #00E5BA !important;
    			background: rgba(255, 255, 255, 0.259) !important;
			}
		}
	}
}
