// colors
$white: #fff;
$blue-dark: #000540;
$light-blue: #00e5ba;
$gray: #afafaf;
$gray2: #707070;
$gray-light: #d7d7db;
$black-opacity: #00000029;
$orange: #fb6300;

// font.size
$normal: 2.3rem;
$button: 3rem;

// breakpoints
$mobile: 479px;
$tablet: 767px;
$desktop: 1023px;
$desktop-mac: 1440px;
