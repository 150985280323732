.invalide{
  border-bottom: 2px solid #cc0000 !important;
}
.invalide-all{
  border: 2px solid #cc0000 !important;
}

.terminos-IN{ height: 70vh; width: 100%; overflow: scroll; padding: 5%;}
.tERMINOS{
  text-align: left;
  h2{
    text-align: center;
    letter-spacing: 0px;
    color: #fb6300;
    font-family: 'product-sans-bold';
    margin: 25px 0 5px;
    font-size: 20px;
    line-height: 42px;
    &.terms{
      margin-top: 42px;
      margin-bottom: 8px;
      @include desktop-min{
        margin-top: 5.3vw;
        margin-bottom: 2.7vw;
      }
    }
    @include mobile{
      font-size: 22px;
      line-height: 24px;
    } 
  }
  h3{
    text-align: center;
    letter-spacing: 0.25px;
    color: #000540;
    font-family: 'quicksand-semibold';
    font-size: 35px;
    margin: 40px 0px 12px;
    line-height: 35px;
    @include mobile{
      font-size: 22px;
      line-height: 24px;
    } 
  }
  p{
    text-align: center;
    letter-spacing: 0px;
    color: #000540;
    font-size: 20px;
    padding: 0 5%;
    margin: 0px;
    @include mobile{
      font-size: 15px;
      line-height: 18px;
    } 
  }
  h2{
    text-align: left !important;
    @include desktop-min{
      font-size: 2vw !important;
    }
  }
  h3{
    font-family: 'quicksand-medium';
    text-align: left !important;
  }
  p{
    text-align: left !important;
    padding-left: 0 !important;
    margin-top: 20px !important; 
  }
}
.boton-close {
  width: 180px;
  font-size: 24px;
  margin-right: 6px;
  letter-spacing: .6px;
  padding-top: 4px;
  background-color: #000540 !important;
  border-radius: 100px;
  width: 300px;
  color: #fff;
  text-decoration: none;
  padding: 10px 2vw;
  margin: 36px 5% 5% 5%;
  position: absolute;
  width: fit-content;
  padding: 10px 4vw;
  bottom: -6px;
  @include desktop-min{
    bottom: -55px;
  }
  &:hover{
    background-color: #00e5ba !important;
    cursor: pointer;
  }
}