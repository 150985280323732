.contain {
  overflow: hidden;
  max-width: 192rem;
  position: relative;
  background-image: url('../../images/modern-office.png');
  background-repeat: no-repeat;
  background-position: center -175px;
  background-size: cover;
  min-height: 100vh;
}

.mod {
  position: absolute;
  background-color: rgba($color: $blue-dark, $alpha: 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.back1 {
  width: 14.823vw;
  position: absolute;
  top: -30%;
  right: 3.5%;
}

.back2 {
  width: 13.594vw;
  position: absolute;
  bottom: -21.5%;
  left: 2%;
}

.logo {
  position: absolute;
  top: 20px;
  left: 25%;
  width: 50vw;
}

.langs {
  position: absolute;
  top: 3%;
  border-radius: 20px 0 0 20px;
  right: 0%;
  max-width: auto;
  width: auto;
  height: 50px;
  min-width: 230px;
  color: #fff;
  display: flex;
  align-items: center;
  background-color: #000540;

  @include mobile {
    min-width: 90px;
    top: 3%;
    right: 0%;
    background-color: transparent;
  }

  .botones {
    width: auto;
    display: flex;
    align-items: center;

    @include mobile {
      flex-direction: column;
      justify-content: center;
    }

    .bt {
      margin: 20%;
      color: #fff;
      border: 0;
      background: transparent;
      font-size: 18px;
      height: auto;
      width: auto;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      &:first-child {
        margin-right: 5px;

        @include mobile {
          margin: 20%;
        }
      }

      &.active {
        color: #00e5ba;
      }

      @include mobile {}

      .flag {
        display: inline-block;
        width: 30px;
        height: 30px;
        margin-right: 5px;
      }

    }

    .flag-es {
      background-image: url('../../images/flagEsp.png');
      background-repeat: no-repeat;
      background-size: cover;
    }

    .flag-en {
      background-image: url('../../images/flagUSA.png');
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

.steps {
  display: none;
}

@media only screen and (min-width: $tablet) {
  .logo {
    width: 30vw;
    top: 4%;
    left: 5%;
  }
}

@media only screen and (min-width: $desktop) {
  .logo {
    top: 2.5%;
    width: 20rem;
  }

  .steps {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 15px;
    top: 15rem;
    left: -12%;

    .step {
      position: relative;
      display: flex;
      background-color: #fff;
      border-radius: 50%;
      width: 70px;
      height: 70px;

      &::after {
        content: '';
        position: absolute;
        border-left: 2px dotted #fff;
        height: 1rem;
        bottom: 102%;
        left: 50%;
      }

      &:first-of-type::after {
        height: 0;
      }

      &.active {
        transform: scale(1.1);

        .icon {
          background: #FB6300;
        }
      }

      &.invalide {
        border: 1px solid red;
      }

      .icon {
        background: #ccc;
        margin: auto;
        width: 40px;
        height: 40px;
      }

      .icon-step2 {
        width: 45px;
        height: 40px;
      }

      .icon-step3 {
        width: 30px;
        height: 43px;
      }
    }

    // .step2 {
    //   &.active {
    //     margin-top: 19rem;

    //     &::after {
    //       content: '';
    //       position: absolute;
    //       border-left: 2px dotted #fff;
    //       height: 19rem;
    //       bottom: 102%;
    //       left: 50%;
    //     }
    //   }

    // }

    // .step3 {
    //   &.active {
    //     margin-top: 89rem;

    //     &::after {
    //       content: '';
    //       position: absolute;
    //       border-left: 2px dotted #fff;
    //       height: 89rem;
    //       bottom: 102%;
    //       left: 50%;
    //     }

    //   }
    // }

    // .step4 {
    //   &.active {
    //     margin-top: 30rem;

    //     &::after {
    //       content: '';
    //       position: absolute;
    //       border-left: 2px dotted #fff;
    //       height: 29rem;
    //       bottom: 102%;
    //       left: 50%;
    //     }
    //   }
    // }
  }
}