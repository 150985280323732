.form-agent.sended {
  overflow: hidden;
  margin-bottom: 50px;
  max-width: 550px;
  min-height: 320px;
}

.enviado {
  margin-top: -100px;
  padding: 5% 2%;

  h2 {
    color: #FB6300;
    font-family: "product-sans-bold";
    margin: 0;
    margin-bottom: 15px;
    font-size: 2.5rem;
  }

  .doble {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;

    .subtitle {
      color: #000;
      // flex: 1;
      font-family: "product-sans";
      margin: 0;
      font-size: 2rem;
      text-align: left;
      margin-top: -20px;
    }

    img {
      max-width: 100%;
      flex: 1;
    }
  }

  .leave {
    width: 12.8rem;
    height: 3.4rem;
    display: flex;
    font-size: 20px;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
}

.input-filecamp {

  // margin-top: 30px;
  &:first-of-type {
    margin-top: 20px;
  }

  margin-bottom: 15px;

  label.top {
    font-size: 1.6rem;
    margin-bottom: 0px;
  }

  label.bottom {
    font-size: 1.3rem;
    margin-bottom: -10px;
    margin-top: 10px;
  }
}

.terminos {
  .checkmark {
    border-radius: 25%;
    height: 20px;
    width: 20px;
    top: -1px;
    left: -12px !important;
  }
}

.bubble_what {
  position: fixed;
  top: 100px;
  right: 50px;
  min-height: 60px;
  width: 60px;
  background-color: #77dd77;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  @include mobile {
    min-height: 40px;
    width: 40px;
    top: 30px;
    left: 30px;
  }

  .icon {
    background-color: #fff;
    width: 36px;
    height: 36px;

    @include mobile {
      width: 30px;
      height: 30px;
    }
  }
}