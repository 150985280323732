// USO className=".icon .icon_name"

.icon {
  content: " ";
  background: #000;
  height: 25px;
  width: 25px;
  position: relative;
  z-index: 1;
  display: flex;
  background-size: contain;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

// como mascara 

// .icon_name {
//   -webkit-mask-image: url("./../../images/name.svg");
//   mask-image: url("./../../images/name.svg");
// }
.icon-cloud_up {
  -webkit-mask-image: url('../../images/Cloud-upload.svg');
  mask-image: url('../../images/Cloud-upload.svg');
}

.icon-up {
  -webkit-mask-image: url('../../images/Upload.svg');
  mask-image: url('../../images/Upload.svg');
}

.icon_file {
  -webkit-mask-image: url("./../../images/document.svg");
  mask-image: url("./../../images/document.svg");
}

.icon_image {
  -webkit-mask-image: url("./../../images/picture.svg");
  mask-image: url("./../../images/picture.svg");
}

.icon_add {
  -webkit-mask-image: url("./../../images/add.svg");
  mask-image: url("./../../images/add.svg");
}

.icon_top {
  -webkit-mask-image: url("./../../images/arrow-up.svg");
  mask-image: url("./../../images/arrow-up.svg");
}

.icon_down {
  -webkit-mask-image: url("./../../images/arrow-down.svg");
  mask-image: url("./../../images/arrow-down.svg");
}

.icon-step1 {
  -webkit-mask-image: url("./../../images/list1step.svg");
  mask-image: url("./../../images/list1step.svg");
}

.icon-step2 {
  -webkit-mask-image: url("./../../images/carnet.svg");
  mask-image: url("./../../images/carnet.svg");
}

.icon-step3 {
  -webkit-mask-image: url("./../../images/document_clip.svg");
  mask-image: url("./../../images/document_clip.svg");
}

.icon-step4 {
  -webkit-mask-image: url("./../../images/boligraf.svg");
  mask-image: url("./../../images/boligraf.svg");
}

.icon-bubble_question {
  -webkit-mask-image: url("./../../images/bubble_question.svg");
  mask-image: url("./../../images/bubble_question.svg");
}

.icon-chain {
  -webkit-mask-image: url("./../../images/chain.svg");
  mask-image: url("./../../images/chain.svg");
}


// como background 
// .icon-next {
//   background-color: transparent;
//   background-image: url("./../../Images/archivo.svg");
// }
.icon_scroll {
  background-color: transparent;
  background-image: url("./../../images/scroll.png");
}

.icon_what-share {
  -webkit-mask-image: url('./../../images/what-share.svg');
  mask-image: url('./../../images/what-share.svg');
}