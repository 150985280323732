.input-file {
  display: none !important;
}

.drag {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.attach {
  position: relative;
  height: auto;
  border-bottom: none;
  padding-right: 5px;
  padding-left: 10px;
  min-height: auto;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .invalide {
    border: 1px solid red;
  }

  .scroller {
    height: 34px;
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .line-color {
      background: #aaa;
      width: 1px;
      height: 3rem;
      display: flex;
      margin-bottom: 5px;
      margin-top: 5px;
    }

    i {
      height: 10px;
      width: 9px;
      background: #aaa;

      &:first-child {
        margin-top: -5px;
      }
    }
  }

  .box-list {
    direction: rtl;
    display: flex;
    align-items: center;
    overflow: auto;
    width: 100%;
    height: 34px;
    flex-wrap: wrap;
    padding-bottom: 0px;
    position: relative;
    justify-content: flex-end;
    padding-left: 15px;
    margin-top: 0px;
    margin-left: -7px;
    gap: 9px;

    /* width */
    &::-webkit-scrollbar {
      width: 5px;
      max-height: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 0px grey;
      border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #aaa;
      border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #000;
    }

    &::-webkit-scrollbar-button {
      width: 3px; //for horizontal scrollbar
      height: 3px; //for vertical scrollbar
    }
  }

  i.icon_scroll {
    background-repeat: no-repeat;
    height: 4rem;
  }

  p {
    font-family: 'product-sans';
    font-size: 15px;
    color: gray;
    padding: 10px 0 0rem 1rem;
    text-align: left;
    vertical-align: top;
    margin: 0;
    position: absolute;
  }
}


.chip-upload {
  position: relative;
  display: inline-block;
  height: 27px;
  font-size: 12px;
  font-family: 'quicksand-bold';
  color: #000;
  line-height: 32px;
  padding: 0 12px;
  border-radius: 16px;
  background-color: #fff;

  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  width: max-content;

  i.icon {
    background: #aaa;
    height: 17px;
    width: 17px;
    margin-right: 5px;
  }
}

.fill,
.chips-uploads.focus {
  border-bottom: 1px solid #000540 !important;
  -webkit-box-shadow: 0 0 0 0 #000540 !important;
  box-shadow: 0 0 0 0 #000540 !important;
  background-color: rgba($color: #000540, $alpha: 0.08) !important;
  border-radius: 1.5rem 1.5rem 0 0 !important;
  padding: .5rem 1rem 0;
  height: auto;
}

.chips-uploads .input:focus,
.chips-uploads .input,
.chips-uploads .input.states_licensed {
  background: none;
  border: none;
  color: #000;
  display: inline-block;
  font-size: 1.5rem;
  height: 2.7rem;
  line-height: 3rem;
  outline: 0;
  margin: 0;
  padding: 0 !important;
  width: 120px !important;
}

.chip-upload .close {
  position: relative;
  margin-left: 0.5rem;
  display: inline;
  opacity: 1;
  cursor: pointer;
  float: right;
  font-size: 16px;
  line-height: 32px;
  padding-left: 8px;
  text-indent: -999999px;
  font-style: normal !important;
}

.chip-upload .close::after {
  content: 'x';
  width: 7px;
  height: 7px;
  opacity: 1;
  color: #aaa;
  position: absolute;
  right: 0;
  text-indent: 0px;
  top: 0;
}

.chips-uploads {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0;
  outline: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: flex;
  flex-wrap: wrap;
  gap: 0.4rem;

  &>div {
    width: 100%;
    background: transparent !important;
    border: none !important;
  }

  input {
    margin: 0 !important;
    padding: 0 !important;
    margin-top: -5px !important;
  }
}

.inp-files.invalide {
  border-color: red !important;
}