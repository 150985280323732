// @import "../utilities/normalize.scss";
// @import "../utilities/animate.scss";


@font-face {
  font-family: "product-sans";
  src: url("../../fonts/ProductSans.ttf");
}

@font-face {
  font-family: "product-sans-bold";
  src: url("../../fonts/ProductSansBold.ttf");
}

html {
  font-size: 16px;
  box-sizing: border-box;
  min-height: 100%;
  ;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  background: #fff;
  font-family: "Helvetica" !important; //
  color: #666;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


*:focus {
  outline: none;
}

// Utilities
@import "../utilities/mixins.scss";
@import "../utilities/icons.scss";
@import "../utilities/App.scss";
@import "../utilities/animate.scss";

// Materialize 
@import '../Components/normalize';
@import '../Components/variable';
@import '../Components/global';
@import '../Components/contain';
@import '../Components/form';
@import '../Components/inputs';
@import '../Components/chips';
@import '../Components/chips-uploads';
@import '../Components/buttons';
@import '../Components/efects';
@import '../Components/modal';
@import '../Components/Firma.scss';

//Components
@import "../Components/policies.scss";
@import "../Components/uploaders.scss";
@import "../Components/_modal_uploader.scss";

// views
@import "../views/View.scss";