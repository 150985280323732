// effect label

.label-up {
	position: relative;
}
.label-up > .label {
	position: absolute;
	top: 0px;
	left: 0;
	cursor: text;
	transition: transform 0.2s ease-out;
	transform-origin: 0% 100%;
	transform: translateY(20px);
}

.label-up > .label:not(.label-icon).activ {
	-webkit-transform-origin: 0% 0%;
	transform-origin: 0% 0%;
	-webkit-transform: translateY(-15px) scale(0.9);
	transform: translateY(-15px) scale(0.9);
}
