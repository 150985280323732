$tablet-width: 768px;
$desktop-width: 1024px;
$desktopL-width: 1440px;
$mobile-width: 480px;

//  mixins media 

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: #{$desktopL-width}) {
    @content;
  }
}

@mixin desktop-min {
  @media (min-width: #{$tablet-width + 1 }) {
    @content;
  }
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
