.form-agent {
  padding-top: 7rem;
  width: 90%;
  min-height: 482px;
  margin: 15rem auto 5rem;
  background-color: #ffffff;
  border-radius: 3.5rem;
  text-align: center;
  position: relative;
  z-index: 2;

  h2 {
    font-family: 'quicksand-bold';
    margin: 0;
    color: $orange;
    margin-bottom: 1.5rem;
    font-size: 3.3rem;
  }

  .subtitle {
    margin: 0;
    font-family: 'product-sans-bold';
    font-size: 1.8rem;
    color: $blue-dark;
    text-align: left;
  }
}

@media only screen and (min-width: $tablet) {
  .form-agent {
    margin: 20rem auto 6rem;
    padding-top: 13%;

    h2 {
      font-size: 3.3rem;
    }
  }
}

@media only screen and (min-width: $desktop) {
  .form-agent {
    margin: 16rem auto 5rem;
    padding-top: 11rem;
    max-width: 72rem;

    h2 {
      margin-bottom: 2.5rem;
    }
  }
}

.perfil {
  box-shadow: 6px 6px 15px #00000029;
  width: 25%;
  // height: 209px;
  position: absolute;
  top: -9%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 25px;
  border-radius: 50%;
}

@media only screen and (min-width: $tablet) {
  .perfil {
    height: 15vw;
    top: -60px;
    padding: 32px;
    width: 15vw;
  }
}

@media only screen and (min-width: $desktop) {
  .perfil {
    height: 11vw;
    top: -80px;
    padding: 32px;
    width: 11vw;
  }
}

@media only screen and (min-width: $desktop-mac) {
  .perfil {
    height: 10vw;
    top: -90px;
    padding: 32px;
    width: 10vw;
  }
}

.check-box {
  text-align: left;

  b {
    color: $orange;
  }
}

.checkbox {
  display: block;
  position: relative;
  padding-left: 11%;
  font-family: 'quicksand-semibold';
  display: inline-block;
  line-height: 1;

  @include desktop-min {
    padding-left: 3%;
  }

  &:hover {
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 2px solid $orange;
    border-radius: 30%;

    @include desktop-min {
      border-radius: 25%;
      height: 20px;
      width: 20px;
      top: -1px;
    }

    &::after {
      content: "";
      position: absolute;
      display: none;
      left: 10px;
      top: -4px;
      width: 7px;
      height: 17px;
      border: solid #fb6300;
      border-width: 0 2.5px 2.5px 0;
      transform: rotate(45deg);

      @include desktop-min {
        top: -6.5px;
        left: 8px;
        border-width: 0 3px 3px 0;
        width: 10px;
        height: 19px;
      }
    }
  }

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;

    &:checked~.checkmark::after {
      display: block;
    }
  }
}

.box {
  padding: 0 6%;
  display: flex;
  flex-direction: column;

  .row:last-of-type {
    margin-bottom: 0;
  }

  .row {
    margin: 0;
    margin-bottom: 1rem;
    display: block;
  }

  .camp14,
  .camp15 {
    // margin-bottom: 2.5rem;
  }
}

@media only screen and (min-width: $tablet) {

  .box,
  .box2 {
    .row:last-of-type {
      margin-bottom: 1.5rem;
    }

    .row {
      margin: 0;
      margin-bottom: 1.6rem;
      display: flex;
      align-items: flex-end;

      .input-field {
        margin-right: 2rem;
      }

      .input-field:last-of-type {
        margin: 0;
      }

      .camp1,
      .camp6 {
        flex: 2;
      }

      .camp2,
      .camp7,
      .camp14,
      .camp15 {
        flex: 1;
      }

      .camp1,
      .camp2,
      .camp6,
      .camp7 {
        position: relative;

        &::before {
          content: "✳";
          position: absolute;
          top: 50%;
          left: -13px;
          color: #fb6300;
        }
      }


    }
  }
}

.box2 {
  padding: 0 6%;
  margin-bottom: 3rem;

  .camp16 {
    padding: 1rem 0;

    &.group-fill {
      margin-top: 15px !important;
    }
  }

  .camp17 {

    width: 100%;

    label {
      align-self: baseline;
      justify-self: flex-end;
      position: absolute;
      width: 150px;
      right: -15px;
      bottom: -1px;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 6rem;

      i {
        background: $light-blue;
        margin-left: 7px;
        height: 20px;
        width: 20px;
      }
    }
  }
}

.p_camp16 {
  margin: 0 0 15px 0;
  font-family: 'product-sans-bold';
  font-size: 1.5rem;
  text-align: left;
}

.camp16 {

  .states,
  .statesM {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 650px;

    @include desktop-min {
      max-height: 350px;
    }

    .state {
      margin-bottom: 10px;
      margin-right: 3%;
    }
  }

  .statesM {
    max-height: 150px;

    @include desktop-min {
      max-height: 80px;
    }
  }

  .checkbox {
    padding-left: 20px;
    text-transform: uppercase;
    font-family: 'product-sans-bold';
    font-size: 1.3rem;
    display: flex;
    align-items: center;

    .checkmark {
      width: 1.6rem;
      height: 1.6rem;

      @include desktop-min {}

      &::after {
        content: "";
        left: 6px;
        top: -6px;
        width: 7px;
        height: 15px;
        border-width: 0 2.5px 2.5px 0;

        @include desktop-min {}
      }
    }

    input {
      &:checked~.check_text {
        color: #000540;
      }
    }

    .check_text {
      margin-top: 2px;
    }
  }
}

.uncertified {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  margin-bottom: 10px;

  &:hover {
    p {

      color: #00E5BA;
    }
  }

  .icon {
    background: #fb6300;
  }

  p {
    margin: 0;
    font-family: 'product-sans';
    font-size: 15px;
    color: #000540;
  }
}

.uncertified_buttons {
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  background-color: #f5f5f5;
  border-radius: 1.6rem;
  padding: 13px;
  margin-bottom: 15px;
  display: none;

  &.active {
    display: flex;
  }
}

.box-moved.active {
  margin-top: 20px;
}

@media only screen and (min-width: $tablet) {
  .box2 {
    margin-bottom: 2rem;

    .camp17 {
      margin-top: 2rem;
      width: fit-content;
      min-width: 99%;
      max-width: 99%;

    }
  }
}

.camp17 {
  // border-bottom: 1px solid $blue-dark;

  img {
    width: 1.7rem;
    margin-left: 1rem;
    vertical-align: bottom;
  }

  .attach {
    position: relative;
    height: 3.4rem;
    border-bottom: none;
    // padding-right: 140px;
    padding-left: 10px;
    min-height: 3.4rem;

    -webkit-box-shadow: 0 0 0 0 $blue-dark;
    box-shadow: 0 0 0 0 $blue-dark;
    background-color: rgba($color: $blue-dark, $alpha: 0.08);
    border-radius: 1.5rem 1.5rem 0 1.5rem;

    display: flex;
    align-items: center;

    .scroller {
      height: 2.4rem;
      margin-top: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .line-color {
        background: $light-blue;
        width: 1px;
        height: 4rem;
        display: flex;
        margin-bottom: .3rem;
      }

      i {
        height: 10px;
        width: 9px;
        background: $light-blue;
      }
    }

    .box-list {
      direction: rtl;
      display: flex;
      align-items: center;
      overflow: auto;
      width: 100%;
      height: 3.4rem;
      flex-wrap: wrap;
      padding-bottom: 0px;
      position: relative;
      justify-content: flex-end;
      padding-left: 15px;
      margin-top: 0px;
      margin-left: -7px;

      /* width */
      &::-webkit-scrollbar {
        width: 5px;
        max-height: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 0px grey;
        border-radius: 50%;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $light-blue;
        border-radius: 50%;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: $blue-dark;
      }

      &::-webkit-scrollbar-button {
        width: 3px; //for horizontal scrollbar
        height: 3px; //for vertical scrollbar
      }
    }

    i.icon_scroll {
      background-repeat: no-repeat;
      height: 4.5rem;
    }

    p {
      font-family: 'product-sans';
      font-size: 1.4rem;
      color: $gray;
      // padding: 10px 0 0rem 1rem;
      text-align: left;
      vertical-align: top;
      margin: 0;
      position: absolute;
    }
  }
}

.box3 {
  .text-area {
    position: relative;
    width: 100%;
    height: 13.5rem;

    .camp20 {
      margin-bottom: 1rem;
    }

    .signature {
      height: 73%;
      resize: none;
      border: none;
    }

    .signature::placeholder {
      font-family: 'product-sans';
      font-size: $normal;
      color: $gray-light;
      padding: 2rem;

    }
  }

  .check-box {
    margin-top: 4rem;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: $tablet) {

  // code...
  .box3 {
    .camp18 {
      flex: 2;
    }

    .camp19 {
      flex: 1;
    }

    .text-area {
      width: 45%;
    }

    .check-box {
      margin-top: 4rem;
      margin-bottom: 20px;
    }

    .camp18,
    .camp19,
    .text-area,
    .check-box {
      position: relative;

      &::before {
        content: "✳";
        position: absolute;
        top: 50%;
        left: -13px;
        color: #fb6300;
      }
    }

    .text-area {
      &::before {
        top: 30%;
        left: -13px
      }
    }

    .check-box {
      &::before {
        top: 25%;
        left: 11px;
      }
    }
  }
}


// .bo1,
// .bo2,
// .bo3,
// .bo4 {
//   display: none;

//   &.open {
//     display: block;
//   }
// }


.form_content {
  padding: 0;
  display: none;

  &.active {
    display: block;
  }

  .slider-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;

    label {
      text-align: center;
      letter-spacing: 0;
      color: #000540;
      font-size: 30px;
      line-height: 30px;
      font-family: 'quicksand-semibold';
      margin-bottom: 20px;

      @include desktop-min {
        font-size: 3.2vw;
        line-height: 3.2vw;
        margin-bottom: .5vw;
      }

      &.recomended {
        font-size: 23px;
        line-height: 0;
        margin-bottom: 25px;

        @include desktop-min {
          font-size: 2.05vw;
        }
      }
    }

    span {
      text-align: center;
      font-family: 'product-sans';
      letter-spacing: 0px;
      color: rgba(0, 229, 186, 1);
      font-size: 15px;
      margin-bottom: 15px;

      @include desktop-min {
        font-size: 2vw;
      }
    }

    @include desktop-min {
      .rc-slider {
        margin-top: 1.9vw;
        height: 1vw;
      }
    }

    .rc-slider-rail {
      background-color: rgba(0, 5, 64, 1);
      height: 2px;

      @include desktop-min {
        height: 0.5vw;
      }
    }

    .rc-slider-mark-text {
      color: #000540;
    }

    .rc-slider-track {
      background-color: rgba(0, 229, 186, 1);
      height: 2px;

      @include desktop-min {
        height: 0.5vw;
      }
    }

    .rc-slider-dot {
      bottom: -15px;
      margin-left: -4px;
      width: 6px;
      height: 6px;
      border: 0px solid rgba(0, 5, 64, 1);
      background: rgba(0, 5, 64, 1);
      display: none;

      &:last-child {
        display: flex;

        @include desktop-min {
          height: 1.3vw;
          width: 1.3vw;
          bottom: -1.4vw;
        }
      }

      &:first-child {
        display: flex;

        @include desktop-min {
          height: 1.3vw;
          width: 1.3vw;
          bottom: -1.4vw;
        }
      }
    }

    .rc-slider-dot-active {
      border-color: #000540;
      background: rgba(0, 229, 186, 1);
    }

    .rc-slider-handle {
      width: 10px;
      height: 10px;
      margin-top: -3.5px;
      cursor: grab;
      border: solid 0px #000540;
      background-color: rgba(0, 229, 186, 1);
      touch-action: pan-x;

      @include desktop-min {
        height: 2.3vw;
        width: 2.3vw;
        bottom: -.6vw;
      }
    }

    .rc-slider-mark {
      position: absolute;
      top: 13px;
      left: 3%;
      width: 96%;
      font-size: 12px;

      @include desktop-min {
        top: 2vw;
        width: 99%;
        left: .5vw;
      }
    }

    .rc-slider-mark span {
      font-family: 'quicksand-semibold';
    }
  }
}

.slect-tag {
  padding: 0 !important;
  width: 100%;
  border: 0;
  height: 3.4rem;
  background: #fff;
  border-bottom: 1px solid #afafaf;
  margin: 0vw;
  z-index: 1;
  position: relative;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid #afafaf;
  padding: 10px 10px 9px 10px;

  &:focus {
    background: rgba(0, 5, 64, 0.08) !important;
  }

  .react-select__control {
    background: transparent;
    height: 3.4rem;
    min-height: 3.4rem;
    border: 0 !important;

    &--is-focused {
      box-shadow: none !important;
      border-radius: 10px 10px 0 0;
      // background: rgba(0, 5, 64, 0.08) !important;
    }


    .react-select__value-container {
      height: 3.4rem;
      padding: 5px 11px 5px 8px;

      @include desktop-min {
        height: 3.4rem;
      }

      &--has-value {
        box-shadow: none !important;
        border-radius: 10px 10px 0 0;
        background: #00054014 !important;
        border-bottom: 1px solid #000540;
      }

      .react-select__placeholder {
        color: #707070;
        line-height: 1;
        top: 51%;
      }

      .react-select__single-value {
        color: #000540;
        line-height: 1;
        top: 51%;
        font-size: 1.7rem;
      }

      &>div :nth-child(2) {
        position: absolute;
      }

      .react-select__input {
        max-width: 2px;

        input {
          background: transparent !important;
          margin-top: -10px !important;
          font-size: 2.3rem !important;
        }
      }
    }

    .css-1g6gooi {
      // display: none;
      margin-top: -1px !important;

      input {
        position: relative;
      }
    }

    .react-select__placeholder {
      top: 100%;
    }

    .react-select__indicators {
      height: 31px;
      position: absolute;
      width: 100%;

      .react-select__indicator-separator {
        display: none;
      }

      .react-select__indicator.react-select__dropdown-indicator {
        padding: 8px 5px 8px 0px;
        position: absolute;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;

        svg {
          fill: #00E5BA !important;
          top: 5px;
          position: relative;
        }
      }
    }

    &--menu-is-open {
      .react-select__value-container {
        background: rgba(0, 5, 64, 0.08) !important;
        border-radius: 10px 10px 0 0;
        padding-top: 10px;

        .react-select__placeholder {
          color: #BABABA;
          top: 50% !important;
        }
      }
    }

    // &--is-focused{  
    //   box-shadow: none !important;
    //   .react-select__value-container{
    //     .react-select__placeholder{
    //       color:#BABABA;
    //       top: 86% !important;
    //     }
    //   }
    // }
  }


  .react-select__menu {
    max-height: 400px;
    background: #000540;
    border-radius: 0 0 20px 20px;
    top: 75%;
    padding-top: 0;
    color: #fff;

    .react-select__menu-list {
      height: 100%;
      max-height: 400px;

      .react-select__option {
        padding: 8px 10px;
        line-height: 1;
        text-align: center;

        &--is-focused {
          color: #fff;
          background: rgba(255, 255, 255, 0.259);
        }

        &--is-selected {
          color: #00E5BA;
          background: transparent;
        }


      }
    }
  }
}