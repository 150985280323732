.modali {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000080;
  z-index: 1005;
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
  top: 0px !important;

  .btn_b {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: 28px;
    padding: 0 5px;
    font-size: 14px;
    border: 1px solid #00e5ba;
    border-radius: 10px;
    color: #000540;
    background-color: #fff;

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 5px;
      background-color: #000540;
    }

    &:disabled {
      border: none;
      background: #ddd;

      &:hover {
        border: none;
        background: #ddd;
        color: #000540;
      }
    }

    &:hover {
      background: #00e5ba;
      color: #fff
    }
  }
}

.modal_upload {
  display: none;

  .upload {
    max-width: 520px;
    // max-width: 65%;
    background-color: #fff;
    border-radius: 25px;
    color: blue;
    text-align: center;
    padding: 20px 50px;

    &.invalide {
      .drag {
        border-color: red;
      }

    }

    .titulo_modal {
      margin: 5px 0 10px 0;
      font-size: 18px;
      color: #000540;
    }

    .drag {
      border: 2px dotted $gray;
      border-radius: 25px;
      padding: 20px 0 50px;
      margin-bottom: 20px;

      .icon {
        width: 131px;
        height: 131px;
        margin: 0 auto 0;
        background-color: $gray;
      }

      p {
        margin: 0;
      }

      &.drag_perfil {
        padding: 20px 27px;
        margin-bottom: 10px;
      }
    }

    .btn_b {
      input {
        display: none;
      }

      &.inp-files {
        position: relative;
        display: inline-flex;


        &.es {
          left: 0;
          margin: auto;

          @include desktop-min {
            left: -70px;
            margin-top: 15px;
            margin-right: 100px;
          }
        }

        &.en {
          left: 0;
          margin: auto;

          @include desktop-min {
            left: -55px;
            // margin-top: 15px;
            // margin-right: 100px;
          }
        }
      }
    }

    .actions_upload {
      input {
        display: none;
      }

      // display: flex;
      position: relative;
      display: inline-flex;
      padding: 0 15px 0 10px;

      &.es {
        top: 8px;
        right: 0px;

        @include desktop-min {
          top: -28px;
          right: -125px;
        }
      }

      &.en {
        top: 8px;
        right: 0px;

        @include desktop-min {
          top: -5px;
          right: -70px;
        }
      }

      .cancelar,
      .continuar {
        min-width: 79px;
      }

      .cancelar {
        margin-right: 15px;
        min-width: 79px;
        margin-left: auto;
      }
    }
  }
}